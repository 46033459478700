import { Grid, Spacing } from './types';
import { deepMerge } from './utils';

export function createThemeGrid(gridInput: Grid, spacing: Spacing): Grid {
  const {
    gridSize = 12,
    gutterWidth = spacing.xs,
    outerMargin = spacing.md,
    ...rest
  } = gridInput;

  const grid: Grid = deepMerge(
    {
      gridSize,
      gutterWidth,
      outerMargin,
    },
    rest,
  );

  return grid;
}
