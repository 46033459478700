import { addons } from '@storybook/addons';
import { theme } from './theme'

import './index.css';

addons.setConfig({
  theme,
  sidebar: {
    showRoots: true,
    collapsedRoots: ['etui-themes', 'etui-colors', 'etui-icons', 'etui-graphics', 'etui-charts'],
  },
});
