import { create } from '@storybook/theming/create';
import { defaultTheme } from '../packages/etui-themes/src/defaultTheme';

export const theme = create({
    base: 'light',
    appBg: defaultTheme.palette.common.bg,
    brandTitle: 'ETUI',
    brandImage: 'logo.svg',
    fontBase: defaultTheme.typography.fontFamily,
    colorPrimary: defaultTheme.palette.brand.main
  });