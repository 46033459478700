import { danfoss } from '@danfoss/etui-colors';
import { Palette } from './types';
import { deepMerge } from './utils';

export default function createThemePalette(paletteInput: Palette): Palette {
  const {
    brand = {
      light: danfoss.red[100],
      main: danfoss.deepRed[100],
    },
    primary = {
      main: danfoss.black[100],
    },
    secondary = {
      main: danfoss.grey[100],
    },
    success = {
      main: danfoss.green[100],
    },
    info = {
      main: danfoss.blue[100],
    },
    error = {
      main: danfoss.red[100],
    },
    warning = {
      main: danfoss.amber[100],
    },
    text = {
      primary: danfoss.black[100],
      secondary: danfoss.grey[100],
      disabled: danfoss.black[40],
    },
    divider = danfoss.black[20],
    action = {
      active: danfoss.blue[100],
      activeBorder: danfoss.black[20],
      hover: danfoss.black[10],
      hoverBorder: danfoss.black[40],
      hoverOpacity: 0.8,
      disabled: danfoss.black[40],
      disabledBackground: danfoss.grey[20],
      disabledOpacity: 0.5,
    },
    defrost = {
      main: danfoss.yellow[100],
    },
    common = danfoss.common,
    grey = danfoss.grey,
    black = danfoss.black,
    amber = danfoss.amber,
    blue = danfoss.blue,
    darkBlue = danfoss.darkBlue,
    deepBlue = danfoss.deepBlue,
    deepRed = danfoss.deepRed,
    green = danfoss.green,
    magenta = danfoss.magenta,
    purple = danfoss.purple,
    red = danfoss.red,
    turquoise = danfoss.turquoise,
    yellow = danfoss.yellow,
    divergingColors = danfoss.divergingColors,
    dataVisualization = danfoss.dataVisualization,
    tonalOffset = 0.2,
    ...other
  } = paletteInput;

  const palette: Palette = deepMerge(
    {
      brand,
      common,
      primary,
      secondary,
      error,
      warning,
      info,
      success,
      grey,
      black,
      amber,
      blue,
      darkBlue,
      deepBlue,
      deepRed,
      green,
      magenta,
      purple,
      red,
      turquoise,
      yellow,
      divergingColors,
      dataVisualization,
      action,
      text,
      divider,
      tonalOffset,
      defrost,
    },
    other,
  );

  return palette;
}
