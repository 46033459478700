import { shadows } from './shadows';
import { createThemeGrid } from './create-theme-grid';
import createThemePalette from './create-theme-palette';
import { createThemeSpacing } from './create-theme-spacing';
import { createThemeTypography } from './create-theme-typography';
import {
  Theme,
  Palette,
  Spacing,
  Shape,
  ZIndex,
  Breakpoints,
  Typography,
  Grid,
} from './types';
import { deepMerge } from './utils';
import { createThemeBreakpoints } from './create-theme-breakpoints';

export const shape: Shape = {
  borderRadius: 2,
  inputHeight: 50,
  inputHeightSmall: 42,
  headerTopHeight: 60,
  headerBottomHeight: 50,
  commandBarHeight: 50,
  tabsHeight: 50,
};

export const zIndex: ZIndex = {
  commandBar: 3,
  modal: 5,
  multiSwitcher: 1,
  header: 4,
  selectInput: 2,
  selectMenu: 11,
};

export function createTheme(overrides: Partial<Theme> = {}, ...args) {
  const {
    palette: paletteInput = {} as Palette,
    spacing: spacingInput = {} as Spacing,
    typography: typographyInput = {} as Typography,
    grid: gridInput = {} as Grid,
    breakpoints: breakpointsInput = [] as Breakpoints,
    ...other
  } = overrides;

  const breakpoints = createThemeBreakpoints(breakpointsInput);
  const palette = createThemePalette(paletteInput);
  const spacing = createThemeSpacing(spacingInput);
  const typography = createThemeTypography(typographyInput);
  const grid = createThemeGrid(gridInput, spacing);

  let theme: Theme = deepMerge(
    {
      breakpoints,
      palette,
      shadows,
      typography,
      spacing,
      shape,
      zIndex,
      grid,
    },
    other,
  );

  theme = args.reduce((acc, argument) => deepMerge(acc, argument), theme);

  return theme;
}
