import { Spacing } from './types';
import { deepMerge } from './utils';

function toPixel(base: number, value: number) {
  return base * value;
}

export function createThemeSpacing(spacingInput: Spacing): Spacing {
  const { baseUnit = 16, spacingUnit = 4, ...other } = spacingInput;

  const factor = 1;
  const multiFactor = spacingUnit / baseUnit; // 0.25 = 4

  const spacing: Spacing = deepMerge(
    {
      baseUnit,
      spacingUnit,
      xxs: toPixel(baseUnit, factor - 3 * multiFactor),
      xs: toPixel(baseUnit, factor - 2 * multiFactor),
      sm: toPixel(baseUnit, factor - 1 * multiFactor),
      md: toPixel(baseUnit, factor),
      lg: toPixel(baseUnit, factor + 1 * multiFactor),
      xlg: toPixel(baseUnit, factor + 2 * multiFactor),
      xxlg: toPixel(baseUnit, factor + 3 * multiFactor),
    },
    other,
  );

  return spacing;
}
