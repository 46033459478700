import { Typography, TypographyStyle, TypographyUtils } from './types';
import { deepMerge } from './utils';

function round(value: number) {
  return Math.round(value * 1e5) / 1e5;
}

const defaultFontFamily = "'myriad-pro', sans-serif";

export function createThemeTypography(typographyInput: Typography) {
  const {
    fontFamily = defaultFontFamily,
    htmlFontSize = 16,
    fontSize = 14,
    fontWeightRegular = 400,
    fontWeightBold = 600,
    fontWeightExtraBold = 800,
    ...rest
  } = typographyInput;

  const coefficient = fontSize / 14;
  const pxToRem: TypographyUtils['pxToRem'] = (size: number) =>
    `${(size / htmlFontSize) * coefficient}rem`;

  const makeTypography = (
    family: string,
    weight: number,
    size: number,
    lineHeight: number,
    letterSpacing: number,
    casing?: object,
  ): TypographyStyle => ({
    fontFamily: family,
    fontWeight: weight,
    lineHeight,
    fontSize: pxToRem(size),
    ...(family === defaultFontFamily
      ? { letterSpacing: `${round(letterSpacing / size)}em` }
      : {}),
    ...casing,
  });

  const typography = deepMerge(
    {
      htmlFontSize,
      pxToRem,
      fontFamily,
      fontSize,
      fontWeightRegular,
      fontWeightBold,
      fontWeightExtraBold,
      fontSizeLarge: pxToRem(16),
      fontSizeBase: pxToRem(14),
      fontSizeSmall: pxToRem(12),
      fontSizeXSmall: pxToRem(10),
      lineHeightTitle: 1.6875,
      lineHeightBase: 1.5,
      lineHeightSmall: 1,
      h1: makeTypography(fontFamily, fontWeightRegular, 64, 1.167, -1.5),
      h2: makeTypography(fontFamily, fontWeightRegular, 48, 1.2, -0.5),
      h3: makeTypography(fontFamily, fontWeightRegular, 32, 1.167, 0),
      h4: makeTypography(fontFamily, fontWeightRegular, 24, 1.235, 0.25),
      h5: makeTypography(fontFamily, fontWeightRegular, 18, 1.334, 0),
      h6: makeTypography(fontFamily, fontWeightBold, 16, 1.6, 0.15),
      button: makeTypography(fontFamily, fontWeightBold, 14, 1.75, 0.6),
      label: makeTypography(fontFamily, fontWeightRegular, 14, 1.5, 0.4),
    },
    rest,
    {
      clone: false,
    },
  );

  return typography;
}
