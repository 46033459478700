import { Breakpoints } from './types';
import { deepMerge } from './utils';

export const createThemeBreakpoints = (breakpointsInput: Breakpoints) => {
  if (Array.isArray(breakpointsInput)) {
    return [
      ...[`${499}px`, `${768}px`, `${992}px`, `${1200}px`],
      ...breakpointsInput,
    ];
  }

  return deepMerge({ xs: 499, sm: 768, md: 992, lg: 1200 }, breakpointsInput);
};
